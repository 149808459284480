import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"
import widget1 from "../../../images/documentation/widget1.png"
import graph1 from "../../../images/documentation/graph1.png"
import graph2 from "../../../images/documentation/graph2.png"
import graph3 from "../../../images/documentation/graph3.png"
import graph4 from "../../../images/documentation/graph4.png"
import graph5 from "../../../images/documentation/graph5.png"
import { Col, Container, Row } from "react-bootstrap"

const GraphInfo = () => {
  return (
    <LayoutComponent>
      <h2>Graphs Info</h2>
      <div>
        <p>
          GAINSS provides real-time insights into your testing progress through
          various widgets.
        </p>
        <img width="100%" alt="graph" src={widget1} />
        <strong>TCs Authored by You</strong>
        <p>
          This widget shows the number of test cases authored by the logged-in
          user. It helps track the contributions of individual team members to
          the overall test suite and allows users to monitor their personal
          progress in test case creation.
        </p>

        <strong>Automation Coverage</strong>
        <p>
          This widget presents the percentage of total test cases that have been
          automated. It helps teams track the adoption of automation in their
          testing efforts, providing a clear picture of how much of the testing
          process is automated vs. manual.
        </p>

        <strong>Manual Test Cycles</strong>
        <p>
          This widget highlights the number of manual test cycles executed in
          the last 4 weeks.
        </p>

        <strong>Automated Test Cycles</strong>
        <p>
          This widget displays the number of fully automated test cycles
          executed in the last 4 weeks. By showing the trends and volume of
          automated test cycles, teams can assess the stability and maturity of
          their automated testing framework over time.
        </p>
      </div>
      <hr />


      <Container>
        <Row>
          <Col>
            <img width="100%" alt="graph" src={graph1} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="p-2 text-justify">
              This graph provides a breakdown of test cases across different
              technology layers such as frontend, backend, database, etc. It helps
              visualize how test cases are distributed among various layers,
              ensuring balanced testing coverage.
            </p>
          </Col>
          <Col>
            <p className="p-2 text-justify">
              This graph displays the percentage of test case coverage for each
              module. It allows teams to quickly see which modules have sufficient
              test coverage for thorough validation.
            </p>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col>
            <img width="100%" alt="graph" src={graph2} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="p-2 text-justify">
              This graph shows how test cases are distributed across different
              modules during test cycles. It provides insights to help teams
              ensure that all modules are being tested in proportion to their
              complexity and importance.
            </p>
          </Col>
          <Col>
            <p className="p-2 text-justify">
              This graph tracks the duration of each test cycle in terms of days.
              It offers insights into the efficiency of the testing process and
              identifying areas for improvement.
            </p>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col>
            <img width="100%" alt="graph" src={graph3} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="p-2 text-justify">
              This graph displays the total number of test cases created each
              week. It helps track weekly test case activities, giving insight
              into the team’s testing velocity and how it changes over time.
            </p>
          </Col>
          <Col>
            <p className="p-2 text-justify">
              This graph shows the weekly progress of automating test cases. It
              highlights the growth of the automation suite by tracking how many
              test cases have been automated each week.
            </p>
          </Col>
        </Row>
      </Container>
      <hr />

      <Container>
        <Row>
          <Col>
            <img width="100%" alt="graph" src={graph4} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="p-2 text-justify">
              This graph displays the number of test cases executed during each
              iteration within a test cycle. It helps track the execution progress
              and ensures that testing is on schedule across iterations.
            </p>
          </Col>
          <Col>
            <p className="p-2 text-justify">
              This graph shows the number of iterations within each test cycle. It
              provides insight into how many iterations are required to complete a
              cycle.
            </p>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col>
            <img width="100%" alt="graph" src={graph5} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="p-2 text-justify">
              This graph tracks the number of new and existing defects identified
              during each test cycle. It visualizes defect trends, helping teams
              quickly assess the quality of the application and identify areas
              where defects are consistently occurring.
            </p>
          </Col>
          <Col>
            <p className="p-2 text-justify">
              This graph shows the pass/fail rate of test case executions within
              test cycles. It allows teams to see the success rate of test
              executions and identify test cycles with high failure rates.
            </p>
          </Col>
        </Row>
      </Container>
      <hr />
    </LayoutComponent>
  )
}

export default GraphInfo
